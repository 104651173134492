<template>
  <div class="textarea">
    <textarea
      class="textarea__node"
      v-on="{
        ...$listeners,
        input: event => $emit('input', event.target.value)
      }"
      v-bind="$attrs"
    />
  </div>
</template>
<script>
export default {
  inheritAttrs: false
};
</script>
