<template>
  <div class="input">
    <input
      v-bind="$attrs"
      v-on="{
        ...$listeners,
        input: event => $emit('input', event.target.value)
      }"
      class="input__node"
    />
  </div>
</template>

<script>
export default {
  inheritAttrs: false
};
</script>
