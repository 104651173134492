<template>
  <div>
    <validation-provider :name="name" rules="required" v-slot="{ errors }">
      <div class="form__field-wrapper" :class="{ error: errors[0] }">
        <slot name="label">
          <h3 class="form__field-label">Message</h3>
        </slot>
        <v-textarea
          v-model="textValue"
          placeholder="Type your message here..."
          class="form__field"
        />
        <div class="form__field-error">{{ errors[0] }}</div>
      </div>
    </validation-provider>
  </div>
</template>

<script>
import vTextarea from "@/components/ui/textarea";

export default {
  components: { vTextarea },

  props: {
    value: { type: String, default: "" },
    name: { type: String, default: "Message" },
  },

  computed: {
    textValue: {
      get() {
        return this.value;
      },
      set(text) {
        this.$emit("input", text);
      },
    },
  },
};
</script>
