<template>
  <div>
    <h3 class="modal__title">Super User</h3>
    <div class="modal__body contact-us__body">
      <loader v-if="loading" container />
      <validation-observer v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <v-email v-model="email" name="email" placeholder="Enter email address">
            <h3 slot="label">Sign in as</h3>
          </v-email>

          <v-message v-model="reason">
            <h3 slot="label">Reason</h3>
          </v-message>

          <ul class="list">
            <li>Do not record samples during Super User sessions</li>
            <li>Do not forget to log out (app/settings)</li>
            <li>Uphold our Privacy Policy</li>
          </ul>

          <div v-if="error" v-html="error" class="text-danger" />

          <div class="modal__footer">
            <button type="button" class="button button_text button_md" @click="onClose">cancel</button>
            <button type="submit" class="button button_secondary button_md">Sign in</button>
          </div>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import vEmail from "@/components/formElements/email";
import vMessage from "@/components/formElements/message";
import Loader from "@/components/loader";
import { formatErrorsToString } from "@/modules/utils";

import { STORE_AUTH_AS_SUPER_USER } from "@/constants";

export default {
  components: { Loader, vEmail, vMessage },

  data: () => ({
    email: null,
    reason: null,
    loading: false,
    error: null,
  }),

  methods: {
    ...mapActions([STORE_AUTH_AS_SUPER_USER]),

    onClose() {
      this.$emit("close");
    },

    onSubmit() {
      this.loading = true;
      this.error = null;

      this[STORE_AUTH_AS_SUPER_USER]({
        email: this.email,
        reason: this.reason,
      })
        .then(this.onSuccess)
        .catch(this.onError);
    },

    onSuccess() {
      this.loading = false;
      this.onClose();
    },

    onError(e) {
      this.loading = false;

      if (e?.response?.data) {
        this.error = formatErrorsToString(e.response.data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  margin: 0;
}
</style>
